
import { connect } from 'react-redux';

import SearchCollections from "components/system/Search/Collections";

import { Form, Row, Col } from 'reactstrap'

import formatText from 'utils/formatText'

const SidebarCallsModalAddCall = ({contact, setContact, contactNumber, setContactNumber}) => {


    return (

        <div>


            <div className='modal-body py-4'>
                <SearchCollections
                    collection={'contacts'}
                    includeFields={['phone']}
                    title={(
                        <span className=" d-block">Select Contact</span>
                    )}
                    value={contact}
                    onChange={(obj) => {
                        setContact(obj.obj)
                        setContactNumber(obj.obj.phone)
                    }}
                /> 
            </div>

            <div className='modal-body pt-3 border-top bg-secondary'>

                {contact && (contact.phone || contact.phone_2 || contact.phone_3) ? (
                    <Form>
                        <p className='text-sm mb-0 font-weight-bold text-uppercase'>Select The Number To Call:</p>

                        {contact.phone ? (
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk-call-contact-select-phone`}
                                    name="archk-call-contact-select"
                                    type="radio"
                                    checked={contactNumber === contact.phone}
                                    onClick={e => setContactNumber(contact.phone)}
                                />
                                <label className="custom-control-label" htmlFor={`archk-call-contact-select-phone`}>
                                    {formatText(contact.phone).phone()} - Main Number
                                </label>
                            </div>
                        ) : ''}
                        {contact.phone_2 ? (
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk-call-contact-select-phone-2`}
                                    name="archk-call-contact-select"
                                    type="radio"
                                    checked={contactNumber === contact.phone_2}
                                    onClick={e => setContactNumber(contact.phone_2)}
                                />
                                <label className="custom-control-label" htmlFor={`archk-call-contact-select-phone-2`}>
                                    {formatText(contact.phone_2).phone()}
                                </label>
                            </div>
                        ) : ''}
                        {contact.phone_3 ? (
                            <div className="custom-control custom-radio mb-3">
                                <input
                                    className="custom-control-input"
                                    id={`archk-call-contact-select-phone-3`}
                                    name="archk-call-contact-select"
                                    type="radio"
                                    checked={contactNumber === contact.phone_3}
                                    onClick={e => setContactNumber(contact.phone_3)}
                                />
                                <label className="custom-control-label" htmlFor={`archk-call-contact-select-phone-3`}>
                                    {formatText(contact.phone_3).phone()} 
                                </label>
                            </div>
                        ) : ''}
                    </Form>
                ) : contact ? (
                    <p className='text-sm text-warning mb-0 border rounded p-3 text-center bg-secondary'>
                        <i className="fas fa-info-circle" /> This contact does not have a valid phone number on file.
                    </p>
                ) : ''}
            </div>
        </div>

    )
}

const mapStateToProps = state => {
    return {
        viewingcontact: state.auth.viewingcontact,
    };
};

export default connect(mapStateToProps, '')(SidebarCallsModalAddCall);