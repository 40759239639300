import { connect } from 'react-redux';

import { useCallback, useEffect, useState } from 'react';


import Search from './Search'
import Pagination from './Pagination'
import TableHeader from './TableHeader'
import TableBody from './TableBody'


export const downloadCSV = (data, options) => {
    if(!options) options = {}
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + data;
    hiddenElement.target = '_blank';
    hiddenElement.download = `${options.name ? options.name : 'export'}.csv`;
    hiddenElement.click();
}

const Table = ({ viewing_user, sizePerPage, totalDocuments, columns, data, placeholder, query,  }) => {

    const defaultSize = sizePerPage ? sizePerPage : viewing_user.settings.default_table_size

    const [size, setSize] = useState(defaultSize);
    const [searchString, setSearchString] = useState('')
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)

    const onSearchChange = useCallback((e) => {
        const val = e.target.value;
        setLoading(true)
        setSearchString(val)
        setPage(1)
        query({
            searchString: val, 
            page: 1,  
            skip: 0,  
            sizePerPage: size,  
            limit: size
        })
    }, [size, query])

    const onPageChange = useCallback((_page) => {
        if(page === _page) return
        if(_page > Math.ceil(totalDocuments / size)) return
        if(_page <= 0) return;

        setPage(_page)
        setLoading(true)

        query({
            searchString, 
            page: _page,  
            skip: size * (_page - 1),  
            sizePerPage: size,  
            limit: size
        })
    }, [page, totalDocuments, size, query, searchString])

    const onSizeChange = useCallback((_size) => {
        _size = parseInt(_size)
        setSize(_size)
        setPage(1)
        setLoading(true)

        query({
            searchString, 
            page: 1,  
            skip: 0,  
            sizePerPage: _size,  
            limit: _size
        })
    }, [searchString, query])

    // only do this once on load
    useEffect(() => {
        query({
            searchString, 
            page: 1,  
            skip: 0,  
            sizePerPage: defaultSize,  
            limit: defaultSize
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setLoading(false)
    }, [data])

    return (
        <div>
            
            <Search loading={loading} placeholder={placeholder} onSearchChange={onSearchChange} />

            <div className='table-responsive react-bootstrap-table'>
                <table className='table'>
                    <TableHeader columns={columns} />
                    <TableBody columns={columns} data={data} />
                </table>
            </div>

            <Pagination 
                totalDocuments={totalDocuments}
                size={size}
                page={page}
                onSizeChange={onSizeChange}
                onPageChange={onPageChange}
            />

        </div>
    )

}



const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(Table);