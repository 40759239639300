
import Overview from '../pages/overview';

import LeadAdd from '../pages/lead_add';
import LeadSearch from '../pages/lead_search';
import LeadOverview from '../pages/lead_overview';

import MatterOverview from '../pages/matter_overview';
import MatterSearch from '../pages/matter_search';

import Resources from '../pages/resources';



const routes = () =>  [


    {
        path: "/referrals/add",
        component: LeadAdd,
    },
    {
        path: "/referrals/:lead_id",
        component: LeadOverview,
    },
    {
        path: "/referrals",
        component: LeadSearch,
    },
    
    {
        path: "/matters/:matter_id",
        component: MatterOverview,
    },
    {
        path: "/matters",
        component: MatterSearch,
    },
   
    {
        path: "/resources",
        component: Resources,
    },

  
    
    {
        path: "/",
        component: Overview,
    },
   
]

export default routes;
