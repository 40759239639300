import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import { Card, CardFooter, CardBody, Container, FormGroup, Input, Row, Col, CardHeader, CardTitle } from "reactstrap";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Circle from 'components/markup/loading/Circle';

import api from 'api';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import ObjectFinder from 'components/system/Objects/Finder';

import PhoneInput from 'react-phone-input-2'

const baseState = {
    lead_source       : '',

    given_name        : '',
    family_name       : '',

    phone             : '',
    email             : '',
    
    notes             : '',
        
    address_line_1    : '',
    address_line_2    : '',
    city              : '',
    state             : '',
    postal_code       : '',
}

const DashboardOverview = ({ selected_division, viewing_user }) => {

    const [loading, setLoading] = useState(false)
    const [possibleSources, setPossibleSources] = useState(null)

    const [referral, setReferral] = useState(baseState)

    const onInputChange = useCallback((field, value) => {
        const r = JSON.parse(JSON.stringify(referral));
        r[field] = value;
        setReferral(r)
    }, [referral])

    const save = useCallback(async () => {
        if(!referral.family_name && !referral.phone && !referral.email) {
            return toast.info(`When adding a referral either a last name, email address, or phone number must be specified.`)
        }

        const lead_source = possibleSources.length === 1 ? possibleSources[0] : referral.lead_source;
        if(!lead_source) return toast.info(`You must select the lead source you are adding this referral for.`)

        setLoading(true)
        const data = await api.affiliates.leadAdd({
            ...referral,
            lead_source,
            url: window.location.href
        })
        console.log(data)
        setLoading(false)

        if(!data.success) {
            return toast.error(`Something went wrong adding this referral, make sure phone numbers and email addresses are formatted correctly and try again.`)
        }

        toast.success(`Referral Added Successfully`);
        setReferral(baseState)

       
    }, [possibleSources, referral])

    useEffect(() => {
        const override = viewing_user.division_overrides.find(d => d.division === selected_division._id);
        setPossibleSources(override ? override.viewable_lead_sources : [])
    }, [selected_division._id, viewing_user.division_overrides])
   
  
    return (

        <>

            <Helmet>
                <title>{`Add Referral`}</title>
                <meta name="description" content="Add Referral" />
            </Helmet>

            {/* <h2 className="display-4 mb-0 text-uppercase mt-4">Daily Activity</h2> */}
            <HeaderNavigation 
                title="Referrals"
                description="Add New"
                leftColSize={4}
                // actionComponent={(
                // //    <img src={image} style={{width: 100}} />
                // )}
            />

            <Container className="ful mt--4 pt-4" fluid>

                <Card>
                    <CardHeader>
                        <CardTitle className="mb-0">Add Info Below</CardTitle>
                    </CardHeader>
                   
                        {possibleSources && possibleSources.length > 1 ? (
                            <CardHeader>
                                <ReactSelect
                                    title="Select Lead Source"
                                    formGroup={true}
                                    onChange={(obj) => onInputChange('lead_source', obj.value)}
                                    options={possibleSources.map(d => {
                                        return {
                                            label: <span><ObjectFinder collection="lead_sources" _id={d} /></span>,
                                            value: d
                                        }
                                    })}
                                    value={referral.lead_source}
                                /> 
                            </CardHeader>
                        ) : ''}

                    <CardHeader>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <label className="form-control-label">First Name</label>
                                    <Input 
                                        type="text"
                                        value={referral.given_name}
                                        onChange={(e) => onInputChange('given_name', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <label className="form-control-label">Last Name</label>
                                    <Input 
                                        type="text"
                                        value={referral.family_name}
                                        onChange={(e) => onInputChange('family_name', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup>
                            <label className="form-control-label">Email Address</label>
                            <Input 
                                type="text"
                                value={referral.email}
                                onChange={(e) => onInputChange('email', e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">Phone Number</label>
                            <PhoneInput
                                country={'us'}
                                value={referral.phone || ''}
                                onChange={phone => onInputChange('phone', phone)}
                            />
                        </FormGroup>
                    </CardHeader>

                    <CardHeader>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 1</label>
                                    <Input 
                                        type="text"
                                        value={referral.address_line_1}
                                        onChange={(e) => onInputChange('address_line_1', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <label className="form-control-label">Address Line 2</label>
                                    <Input 
                                        type="text"
                                        value={referral.address_line_2}
                                        onChange={(e) => onInputChange('address_line_2', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <label className="form-control-label">City</label>
                                    <Input 
                                        type="text"
                                        value={referral.city}
                                        onChange={(e) => onInputChange('city', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <label className="form-control-label">State</label>
                                    <Input 
                                        type="text"
                                        value={referral.state}
                                        onChange={(e) => onInputChange('state', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <label className="form-control-label">Zip Code</label>
                                    <Input 
                                        type="text"
                                        value={referral.postal_code}
                                        onChange={(e) => onInputChange('postal_code', e.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                        </Row>

                    </CardHeader>

                    <CardBody>
                        <FormGroup>
                            <label className="form-control-label">Notes</label>
                            <Input 
                                type="textarea"
                                value={referral.note}
                                onChange={(e) => onInputChange('note', e.target.value)}
                            />
                        </FormGroup>
                    </CardBody>

                    <CardFooter className="text-right">
                        <button disabled={loading} onClick={save} className="btn btn-success">Add Referral</button>
                    </CardFooter>

                </Card>

               
                <div className="pb-6" />
            
            </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    socket: state.socket,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(DashboardOverview);
