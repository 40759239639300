import * as actionTypes from '../../actions';
import store from 'store';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';

import { toggleAlertBS } from 'store/functions/system/system';
import { Device } from 'twilio-client';


import api from 'api'
import Axios from 'axios';

import { toast } from 'react-toastify';

import { JOIN_AGENT_ROOM, LEAVE_AGENT_ROOM, TRANSFER_DID_NOT_ANSWER } from 'sockets/events'

const cookies = new Cookies();

export const playSound = () => {
    Device.audio.ringtoneDevices.test('/Ringtone.m4a')

    // setTimeout(() => {
    //     Device.audio.disconnect(false)
    //     Array.from(document.querySelectorAll('audio, video')).forEach(el => el.muted = true)

    // }, 500)
}

export const toggleMute = () => {
    if(Device.activeConnection().isMuted()) {
        Device.activeConnection().mute(false)
        return false
    } else {
        Device.activeConnection().mute(true)
        return true
    }
}

// user and contact are populated objects
export const addCall = async ({type, user, userNumber, contact, contactNumber, number, callSid}) => new Promise (async resolve => {

    if(!number.includes('+')) number = '+' + number;

    const params = {
        user    : user ? user._id : null,
        contact : contact ? contact._id : null,
        type,
        userNumber,
        contactNumber,
        number,
        callSid
    }

    const added = await api.call_center.addCall(params)

    if(!added.success) {
        toast.error(
            `Transfer could not be completed at this time, if you have entered a ` +
            `phone number please double check that it is a valid US based phone number.`
        )
        return resolve(false)
    }

    resolve(true)

    let display_name = number
    let _id = number

    if(user) {
        display_name = user.display_name;
        _id = user._id
    } else if(contact) {
        if(contact.display_name) {
            _id = contact._id
            display_name = contact.display_name
        } else {
            display_name = contactNumber
        }
    }

    dispatch({ transfer_call: { _id, display_name, type, can_merge: false } })

    const addListener = (tries = 0) => {
        if(tries > 20) return ;

        setTimeout(() => {
            const merge = document.getElementById('archk-call-merge')
            const cancelTransfer = document.getElementById('archk-cancel-transfer')
    
            if(!merge || !cancelTransfer) return addListener(tries + 1)

            merge.addEventListener('click', async () => {
                const merged = await api.call_center.mergeCall(params)
                if(!merged.success) return toast.error(`Could not merge call, please try again.`)

                if(merged.data && merged.data.status === 'Contact Hung Up')  {
                    toast.warning(`The contact you are trying to merge calls with has hung up.`)
                    const endButton = document.getElementById('archk-call-button-end')
                    if(endButton) endButton.click()
                }

                dispatch({
                    transfer_call: {}
                })
            })
    
            cancelTransfer.addEventListener('click', async () => {
                const cancelled = await api.call_center.cancelTransfer({
                    callSid
                })

                if(!cancelled.success) return toast.error(`Could not cancel call, please try again.`)

                dispatch({
                    transfer_call: {}
                })
            })

        },  250)
       
    }

    addListener();
})

const dispatch = (payload) => {
    store.dispatch({ type: actionTypes.SET_CALL_CENTER, payload });
}

const removeIncomingCall = () => {
    dispatch({incoming_call: {}})
}

const removeCurrentCall = () => {
    dispatch({current_call: {}, transfer_call: {}})
}

const getCallParams = (conn) => {
    return {
        name: conn.customParameters.get('name'),
        number: conn.customParameters.get('number'),
        address: conn.customParameters.get('address'),
        call_sid: conn.parameters.CallSid,
    }
}

const fetchAndSetToken = async () => new Promise (async resolve => {
    const token = await api.call_center.accessToken();

    try {  

        if(token.message && token.message[0] === "Invalid Twilio Configuration, Check Credentials") {
            console.error(
                `Twilio error, this error means your twilio account is not configured ` +
                `properly and settings must be updated in your admin dashboard.`
            )
            return toast.error(`Invalid Twilio Configuration, Check Credentials Or Contact Your System Administrator`)
        }

        Device.setup(token.data.token, {
            codecPreferences: ["opus", "pcmu"],
            fakeLocalDTMF: true,
            enableRingingState: true,
            closeProtection: true,
            sounds: {
                // kill the connected sound by placing a bad url here
                outgoing: 'test',
                incoming: '/Ringtone.m4a',
            }
        });
        return resolve(true)

    } catch(e) {
        console.log(e)
        toast.error(
            `Browser calling could not be setup. Refresh your browser and try ` +
            `again or visit https://networktest.twilio.com for troubleshooting.`
        )
        dispatch({ browser_dialing: 'error' })
        return resolve(false)
    }
})

// const fetchConnectionParams = (conn) => {
//     return {
//         call_phone_number_id        : conn.customParameters.get('call_phone_number_id'),
//         call_phone_number           : conn.customParameters.get('call_phone_number'),
//         company_id                  : conn.customParameters.get('company_id'),
//         company_name                : conn.customParameters.get('company_name'),
//         division_id                 : conn.customParameters.get('division_id'),
//         division_name               : conn.customParameters.get('division_name'),
//         lead_source_id              : conn.customParameters.get('lead_source_id'),
//         lead_source_name            : conn.customParameters.get('lead_source_name'),
//         case_id                     : conn.customParameters.get('case_id'),
//         case_name                   : conn.customParameters.get('case_name'),
//         contact_id                  : conn.customParameters.get('contact_id'),
//         contact_identifier          : conn.customParameters.get('contact_identifier'),
//         contact_given_name          : conn.customParameters.get('contact_given_name'),
//         contact_family_name         : conn.customParameters.get('contact_family_name'),
//         contact_phone               : conn.customParameters.get('contact_phone'),
//         contact_phone_2             : conn.customParameters.get('contact_phone_2'),
//         contact_phone_3             : conn.customParameters.get('contact_phone_3'),
//         contact_email               : conn.customParameters.get('contact_email'),
//         case_manager_id             : conn.customParameters.get('case_manager_id'),
//         case_manager_identifier     : conn.customParameters.get('case_manager_identifier'),
//         case_manager_given_name     : conn.customParameters.get('case_manager_given_name'),
//         case_manager_family_name    : conn.customParameters.get('case_manager_family_name'),
//         case_manager_phone          : conn.customParameters.get('case_manager_phone'),
//         case_manager_email          : conn.customParameters.get('case_manager_email'),
//         is_new                      : conn.customParameters.get('is_new'),
//         type                        : conn.customParameters.get('type'),
//     }
// }

// const addOnCallListeners = (conn) => {
//     const addListeners = (tries) => {
//         if(!tries > 100) return;
//         const transfer = document.getElementById('archk-transfer');
//         const toggleMute = document.getElementById('archk-toggle-mute');
//         const toggleHold = document.getElementById('archk-toggle-hold');
//         const endCall = document.getElementById('archk-end-call');

//         if(!transfer || !toggleMute || !toggleHold || !endCall) return setTimeout(() => {
//             addListeners(tries + 1)
//         }, 200)

//         transfer.addEventListener('click', async (e) => {
//             // const transfer = await _call_center.transfer({
//             //     call_sid: conn.parameters.CallSid, 
//             //     user_id: e.target.innerText,
//             //     ...fetchConnectionParams(conn),
//             // })
//             // if(!transfer.success) return toggleAlertBS('info', 'Please try again.')

//             // const transferId = transfer.data.transferId
            
//             // store.dispatch({
//             //     type: actionTypes.SET_TRANSFER,
//             //     payload: transferId
//             // });

//         })

//         toggleMute.addEventListener('click', async () => {
//             // const state = store.getState();
//             // const mute = state.state.on_call.mute ? false : true
//             // Device.activeConnection().mute(state.state.on_call.mute ? false : true);

//             // store.dispatch({
//             //     type: actionTypes.SET_STATE,
//             //     payload: {
//             //         on_call: {
//             //             ...state.state.on_call,
//             //             mute
//             //         }
//             //     }
//             // });

//         })

//         toggleHold.addEventListener('click', async () => {
//             // const state = store.getState();
//             // const setOnHold = await _call_center.toggleHold({
//             //     callSid: conn.parameters.CallSid, 
//             //     contact_id: conn.customParameters.get('contact_id'),
//             //     hold: state.state.on_call.hold ? false : true
//             // })

//             // if(setOnHold.data) {
//             //     store.dispatch({
//             //         type: actionTypes.SET_STATE,
//             //         payload: {
//             //             on_call: {
//             //                 ...state.state.on_call,
//             //                 hold: setOnHold.data.hold
//             //             }
//             //         }
//             //     });
//             // }
            
//         })

//         endCall.addEventListener('click', () => {
//             Device.disconnectAll();
//             setOffCall()
//         })
//     }
//     addListeners(0)
// }

// const setDeviceListeners = () => {

//     // wont fire on login but fires every other time a user refreshes the page
//     Device.on('ready', () => {
//         store.dispatch({ type: actionTypes.SET_BROWSER_DIALING_STATUS, payload: { status: 'ready'}, });
//     })

//     Device.on('disconnect', (conn) => {
//         setIncomingCall(false);
//         setCallReview(fetchConnectionParams(conn))
//         setOffCall();
//     })
    
//     // fired if call not picked up
//     Device.on('cancel', (conn) => {
//         setIncomingCall(false);
//         setOffCall();
//     })

//     // log any device errors
//     Device.on('error', async (err) => {
//         // if there is an error try to refresh the token
//         // const set = await fetchAndSetToken();

//         // if(!set) {
//         //     setOffCall()
//         //     setIncomingCall(false)
//         //     store.dispatch({type: actionTypes.SET_BROWSER_DIALING_STATUS, payload: { status: 'error'}, });
//         //     return toggleAlertBS('Please refresh your page to continue making calls')
//         // }
//         console.log(err)

//     })

//     Device.on('connect', async (conn) => {   
//         // const status = document.getElementById('archk-call-status')
//         // const circle = document.getElementById('archk-call-status-circle')
//         // if(status) status.innerText = 'Connected'
//         // if(circle) circle.classList.add('Answered')

//         // const type = conn.customParameters.get('type')
//         // const contact_id = conn.customParameters.get('contact_id')

//         // // mark all queue calls as done when a user connects to a client for that type
//         // if(type === 'hotline') {
//         //     _call_queues.markConnected({contact: contact_id, type: 'hotline'})
//         // } else {
//         //     _call_queues.markConnected({contact: contact_id, type: 'call back'})
//         // }

//         // try {
//         //     // TODO: add in call direction voip_campaign_name on start and stop
//         //     // 
//         //     let e = store.getState().auth.viewing_user.email
//         //     if(e === 'jmaher@fairmaxlaw.com') e = 'john@architeck.io'
//         //     console.log(conn)
//         //     await Axios({
//         //         method: 'post',
//         //         url: `https://desktop.baltocloud.com/start/by_email?email=${e}&token=NiTd5rijtY&integration=twilio_vendor`,
//         //         data: {
//         //             voip_call_id: conn.parameters.CallSid
//         //         }
//         //     })
    
//         // } catch(e) {
//         //     console.log(e)
//         // }
    

//     })
    
//     Device.on('incoming', (conn) => {
//         setIncomingCall(fetchConnectionParams(conn));

//         // if(window.location.href.includes('hotline')) {
//         //     const dialType = conn.customParameters.get('dial_type');

//         //     // reject direct case manager calls if we are in the hotline
//         //     // this fails to load the hotline UI if not taken
//         //     // as a "queue" call
//         //     if(dialType === 'case_manager') {
//         //         conn.reject()
//         //         return setIncomingCall(false);
//         //     }

//         //     try {
//         //         const isReviewing =  store.getState().state.call_review.contact_id;
//         //         if(!isReviewing) {
//         //             // take the queue call
//         //             return setTimeout(() => {
//         //                 setOnCall(fetchConnectionParams(conn))
//         //                 conn.accept()
//         //                 _call_queues.markConnected({contact: conn.customParameters.get('contact_id'), type: 'hotline'})
//         //                 addOnCallListeners(conn)
//         //                 _call_center.callAccepted({contact: conn.customParameters.get('contact_id')})
//         //                 return setIncomingCall(false);
//         //             }, 1000)
//         //         }
//         //     } catch(e) {}

          
//         // }

//         // const initListeners = (tries) => {
//         //     if(!tries > 100) return;
//         //     const accept = document.getElementById('archk-accept-call');
//         //     const decline = document.getElementById('archk-decline-call');

//         //     if(!accept || !decline) return setTimeout(() => {
//         //         initListeners(tries + 1)
//         //     }, 200)

//         //     accept.addEventListener('click', () => {
//         //         setOnCall(fetchConnectionParams(conn))
//         //         conn.accept()
//         //         addOnCallListeners(conn)
//         //         _call_queues.markConnected({contact: conn.customParameters.get('contact_id'), type: 'hotline'})
//         //         _call_center.callAccepted({contact: conn.customParameters.get('contact_id')})
//         //         setIncomingCall(false);
//         //     })

//         //     decline.addEventListener('click', () => {
//         //         conn.reject()
//         //         setIncomingCall(false);
//         //     })

//         // }
//         // initListeners(0)
//     })

// }

// export const dialOut = async (params, isQueue) => {
//     // const state = store.getState();

//     // const info = await _call_center.getInfo(params.contact_id, params.case_id);
//     // if(!info.data) return toggleAlertBS(true, 'Please try again.')

//     // const data = {
//     //     ...info.data,
//     //     user_id: state.auth.viewing_user._id,
//     //     phoneNumber: params.phone,
//     //     conference: params.conference,
//     //     type: params.type,
//     //     is_queue: isQueue ? true : false,
//     //     call_sid: params.call_sid,
//     // }

//     // if(state.auth.viewing_user.call_phone_default === 'phone') {
//     //     const called = await _call_center.callFromPhone(data)
//     //     console.log(called)
//     // } else {

//     //     const conn = Device.connect(data) 

//     //     let tries = 0

//     //     let interval = setInterval(() => {
//     //         if(conn.status() === 'open' || conn.status() === 'ringing' || conn.status() === 'connecting') {
//     //             setOnCall({...info.data, contact_phone: params.phone});
//     //             addOnCallListeners(conn)
                
//     //             // if we dial out mark all hotline calls as connected
//     //             _call_queues.markConnected({contact: info.data.contact_id, type: 'all'})
//     //             return clearInterval(interval)
//     //         }
//     //         tries++

//     //         if(tries > 40) return clearInterval(interval)
//     //     }, 500)

//     //     conn.on('ringing', (isRinging) => {   

//     //     })

//     // }

// }

// // for params see fetchConnectionParams
// export const setOnCall = (params) => {

//     document.body.classList.add('archk-bg-on-call')
 
//     store.dispatch({
//         type: actionTypes.SET_STATE,
//         payload: {
//             on_call: params
//         }
//     });

// }

// export const setOffCall = () => {
//     document.body.classList.remove('archk-bg-on-call')

//     setTimeout(() => {
//         store.dispatch({
//             type: actionTypes.SET_STATE,
//             payload: {
//                 on_call: {}
//             }
//         });
//     }, 750)
// }
// export const setIncomingCall = (payload) => {
//     store.dispatch({
//         type: actionTypes.SET_INCOMING_CALL,
//         payload: payload
//     });
// }


// export const setCallReview = (payload) => {
//     store.dispatch({
//         type: actionTypes.SET_CALL_REVIEW,
//         payload: payload
//     });
// }
// export const closeCallReview = (payload) => {
    // store.dispatch({
    //     type: actionTypes.SET_CALL_CENTER,
    //     payload: {}
    // });
// }



// this function will create a device with twilio allowing the user to dial via browser if they have that as their call setting
export const enableDialing = async () => new Promise (async resolve => {
    const state = store.getState();
    if(state.call_center.browser_dialing === 'ready') return resolve(true);

    dispatch({ browser_dialing: 'loading' })

    const set = await fetchAndSetToken()
    if(!set) return resolve(false)

     // wont fire on login but fires every other time a user refreshes the page
    Device.on('ready', (a) => {
        const socket = store.getState().socket;
        socket.emit(JOIN_AGENT_ROOM, {})

        socket.on('CALL_CENTER.TRANSFER_DID_NOT_ANSWER', (params) => {
            dispatch({
                transfer_call: {}
            })
        })

        socket.on('CALL_CENTER.TRANSFER_ANSWERED', (params) => {
            dispatch({
                transfer_call: {
                    ...store.getState().call_center.transfer_call,
                    can_merge: true
                }
            })
        })

        // https://www.twilio.com/docs/voice/sdks/javascript/v1/device#audio

        dispatch({ browser_dialing: 'ready' })
        
        return resolve(true)
    })

    // log any device errors
    Device.on('error', async (err) => {
        const socket = store.getState().socket;
        socket.emit(LEAVE_AGENT_ROOM, {})

        dispatch({ browser_dialing: 'error' })
    })

    Device.on('disconnect', (conn) => {
        // setIncomingCall(false);
        // setCallReview(fetchConnectionParams(conn))
        // setOffCall();
        removeIncomingCall()
        removeCurrentCall()
    })
    
    // fired if call not picked up
    Device.on('cancel', (conn) => {
        // setIncomingCall(false);
        // setOffCall();
        removeIncomingCall()

    })

    Device.on('ringing', async (conn) => {   
        console.log('ringing')
    })

    Device.on('connect', async (conn) => {   
        console.log('asdfasdf')
        removeIncomingCall()
        dispatch({current_call: getCallParams(conn)})

        // setTimeout(() => {
        //     const transfer = document.getElementById('archk-call-add')

        //     transfer.addEventListener('click', () => {

        //         // api.call_center.addCall({
        //         //     user: '658435e559991467d4e30e2b',
        //         //     call_sid: conn.parameters.CallSid
        //         // })

        //         // dispatch({
        //         //     transfer_call: {
        //         //         display_name: 'Shannon Maher',
        //         //         user_id: '658435e559991467d4e30e2b'
        //         //     }
        //         // })

        //         // setTimeout(() => {
        //         //     const merge = document.getElementById('archk-call-merge')
        //         //     const cancelTransfer = document.getElementById('archk-cancel-transfer')

        //         //     merge.addEventListener('click', () => {
        //         //         api.call_center.mergeCall({
        //         //             user: '658435e559991467d4e30e2b',
        //         //             call_sid: conn.parameters.CallSid
        //         //         })
        //         //         dispatch({
        //         //             transfer_call: {}
        //         //         })
        //         //     })

        //         //     cancelTransfer.addEventListener('click', () => {
        //         //         api.call_center.cancelTransfer({
        //         //             user: '658435e559991467d4e30e2b',
        //         //             call_sid: conn.parameters.CallSid
        //         //         })
        //         //         dispatch({
        //         //             transfer_call: {}
        //         //         })
        //         //     })
        //         // }, 2000)


        //     })
        // }, 2000)

        // const status = document.getElementById('archk-call-status')
        // const circle = document.getElementById('archk-call-status-circle')
        // if(status) status.innerText = 'Connected'
        // if(circle) circle.classList.add('Answered')

        // const type = conn.customParameters.get('type')
        // const contact_id = conn.customParameters.get('contact_id')

        // // mark all queue calls as done when a user connects to a client for that type
        // if(type === 'hotline') {
        //     _call_queues.markConnected({contact: contact_id, type: 'hotline'})
        // } else {
        //     _call_queues.markConnected({contact: contact_id, type: 'call back'})
        // }

        // try {
        //     // TODO: add in call direction voip_campaign_name on start and stop
        //     // 
        //     let e = store.getState().auth.viewing_user.email
        //     if(e === 'jmaher@fairmaxlaw.com') e = 'john@architeck.io'
        //     console.log(conn)
        //     await Axios({
        //         method: 'post',
        //         url: `https://desktop.baltocloud.com/start/by_email?email=${e}&token=NiTd5rijtY&integration=twilio_vendor`,
        //         data: {
        //             voip_call_id: conn.parameters.CallSid
        //         }
        //     })
    
        // } catch(e) {
        //     console.log(e)
        // }
    

    })
    
    Device.on('incoming', (conn) => {
        dispatch({incoming_call: getCallParams(conn)})

        const initListeners = (tries) => {
            if(!tries > 100) return;
            const accept = document.getElementById('archk-accept-call');
            const decline = document.getElementById('archk-decline-call');

            if(!accept || !decline) return setTimeout(() => {
                initListeners(tries + 1)
            }, 200)

            accept.addEventListener('click', () => {
                // setOnCall(fetchConnectionParams(conn))
                conn.accept()
                // addOnCallListeners(conn)
                // _call_queues.markConnected({contact: conn.customParameters.get('contact_id'), type: 'hotline'})
                // _call_center.callAccepted({contact: conn.customParameters.get('contact_id')})
                // setIncomingCall(false);
                removeIncomingCall()
            })

            decline.addEventListener('click', () => {
                conn.reject()
                dispatch({incoming_call: {}})
            })

        }
        initListeners(0)
    })


})

// number is the number to call, contact, division, and matter are just ids
export const dialNumber = async ({name, address, number, contact, division, matter, call_queue_entry}) => {
    let call = await Device.connect({ name, address, number, contact, division, matter, call_queue_entry });
    console.log(call)
}
