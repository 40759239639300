/*
Documentation

this is the footer for the dashboard layout

*/

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Nav, NavItem, Row } from "reactstrap";
import keys from 'keys'
import A from 'components/markup/links/A'

const Footer = ({viewing_user}) => (
    <div id="dashboard-footer" className="footer-wrapper">
        <Container fluid>
            <footer className="footer">
                <Row className="align-items-center justify-content-lg-between">

                    <Col lg="5">
                        <div className="copyright text-center text-lg-left my-4 my-md-0">
                            © {new Date().getFullYear()}{' '}
                            {keys.COMPANY}
                            {' '}&mdash;{' '}
                            <a className="font-weight-bold ml-1 " href="https://architeck.io" rel="noopener noreferrer" target="_blank">
                                IntuiDesk LLC
                            </a>
                        </div>
                    </Col>

                    <Col lg="7">

                        <Nav className="nav-footer justify-content-center justify-content-lg-end">

                            <NavItem>
                                <Link className=" nav-link" to="/settings">My Profile</Link>
                            </NavItem>

                            <NavItem>
                                <A className=" nav-link" href={`${keys.SITE_URL}/legal/terms-of-service`}>Terms Of Service</A>
                            </NavItem>
                            <NavItem>
                                <A className=" nav-link" href={`${keys.SITE_URL}/legal/privacy-policy`}>Privacy</A>
                            </NavItem>

                            <NavItem>
                                <Link className=" nav-link" href="logout" to="/logout">Logout</Link>
                            </NavItem>

                        </Nav>

                    </Col>

                </Row>
            </footer>

        </Container>
    </div>
)


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(Footer);