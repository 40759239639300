// https://www.twilio.com/docs/voice/sdks/javascript

import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import NameCircle from 'components/markup/misc/NameCircle';

import formatText from 'utils/formatText'

const CallsOnCall = ({incoming_call}) => {

    const internalTransfer = incoming_call.number === 'Internal Transfer'

   return (
        <div>
            <div 
                className={`${incoming_call.number === 'Internal Transfer' ? 'bg-gradient-purple' : 'bg-gradient-info'} text-center archk-call pt-4 pb-2 cursor-pointer`} 
                style={{overflow: 'hidden'}}
            >
                <div className='pt-3'>
                    <p className='text-sm text-white mb-1 text-uppercas'><i className="fas fa-headset mr-2" /> Direct Dial</p>
                    <NameCircle className="z-depth-2 Answered" width={70} contact={{given_name: 'John', family_name: 'Maher'}}  />{' '}
                    <p className='text-sm mb-0 mt-3 text-white font-weight-bold'>{incoming_call.name}</p>
                    <p className='text-sm mb-0 mt--2 text-white font-weight-bold'>{formatText(incoming_call.number).phone()}</p>
                    {internalTransfer ? '' : (
                        <p className='text-sm mb-3 mt--2 text-white font-weight-bold'>{incoming_call.address ? incoming_call.address : 'Unknown Address'}</p>
                    )}
                </div>
            </div>

            <div className='text-center archk-call pt-0 pb-3  border-bottm bg-dark border-top' style={{overflow: 'hidden'}}>
                <div className="archk-on-call-wapper archk-call-buttons text-centr   mt-4 mb-2 px-4">
                    <Row>

                        <Col md={6} className='text-center px-2'>
                            <button  id="archk-decline-call" className='btn btn-block btn-sm btn-danger' >
                                Decline 
                            </button>                           
                        </Col>

                        <Col md={6} className='text-center px-2'>
                            <button  id="archk-accept-call" className='btn btn-block btn-sm btn-success'>
                                Accept 
                            </button>
                        </Col>

                    </Row>
                </div>
            </div>
        </div>
   )

}


const mapStateToProps = state => {
	return {
	    incoming_call: state.call_center.incoming_call,
	};
};

export default connect(mapStateToProps, '')(CallsOnCall);
