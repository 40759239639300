
import { connect } from 'react-redux';

import { Form, Row, Col } from 'reactstrap'

import SearchCollections from "components/system/Search/Collections";

const SidebarCallsModalAddCall = ({user, setUser, userNumber, setUserNumber}) => {


    return (

        <div>
            <div className='modal-body'>

                <SearchCollections
                    collection={'users'}
                    showEnhanced="users"
                    title={(
                        <span className=" d-block">Select User (Type to refresh results)</span>
                    )}
                    value={user ? user._id : user}
                    onChange={(obj) => {
                        setUser(obj.obj)
                        setUserNumber(obj.obj._id)
                    }}
                    filter={{
                        removeSelf: true
                    }}
                    sort={{
                        agent_can_receive_calls: -1,
                        agent_current_call: 1,
                        given_name: 1
                    }}
                /> 
            </div>

            <div className='modal-body bg-secondary border-top pt-3'>

                {user ? (
                    <div>
                        <Form onSubmit={e => e.preventDefault()}>
                            <Row>
                                <Col md={5}>
                                    <div className="custom-control custom-radio mb-3">
                                        {/* <div className='text-center'><i style={{fontSize: 24}} className="fas fa-headset text-success" /> </div> */}
                                        <input
                                            className="custom-control-input"
                                            id={`archk-call-user-number-id`}
                                            name="archk-call-user"
                                            type="radio"
                                            checked={userNumber === user._id}
                                            onClick={e => setUserNumber(user._id)}
                                        />
                                        <label className="custom-control-label" htmlFor={`archk-call-user-number-id`}>
                                            Call {user.display_name} 
                                        </label>
                                    </div>
                                </Col>

                                <Col md={7}>
                                    {user.phone.includes('+1') ? (
                                        <div className="custom-control custom-radio mb-3">
                                            {/* <div className='text-center'><i style={{fontSize: 24}} className="fas fa-phone text-warning text-success" /> </div> */}
                                            <input
                                                className="custom-control-input"
                                                id={`archk-call-user-number-phone`}
                                                name="archk-call-user"
                                                type="radio"
                                                checked={userNumber === user.phone}
                                                onClick={e => setUserNumber(user.phone)}
                                            />
                                            <label className="custom-control-label" htmlFor={`archk-call-user-number-phone`}>
                                                Transfer To {user.display_name}'s Cell Phone
                                            </label>
                                        </div>
                                    ) : ''}
                                </Col>
                            </Row>
                           
                           
                        </Form>
                    </div>
                ) : ''}

            </div>

        </div>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(SidebarCallsModalAddCall);