import { Card, CardHeader, CardTitle, Row, Col, Badge } from "reactstrap";

import { formatCurrency } from 'utils/currency';
import formatText from 'utils/formatText';

import ObjectFinder from 'components/system/Objects/Finder';

import moment from 'moment';

const DashboardOverview = ({ data }) => {

    const startOfDay = parseInt(moment().startOf('day').format('X'))

    return (
        <Card>
            <CardHeader>
                <Row>
                    <Col md={6} className="align-self-center">
                        <CardTitle className='mb-0 text-ceter'>Recent Pipeline Activity</CardTitle>
                    </Col>
                    <Col md={6} className="align-self-center text-right">
                        <button className="btn btn-sm btn-outline-info">See All</button>
                    </Col>
                </Row>
            </CardHeader>
    
            <div className='table-responsive'>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Current Step</th>
                            <th>Last Step</th>
                            <th>Last Note</th>
                            <th className='text-right'>Total $</th>
                            <th className='text-right'></th>
                        </tr>
                    </thead>
    
                    <tbody>
                        {data.recentActivity.length ? data.recentActivity.map(c => (
                            <tr key={c._id}>
                                <td>
                                    <div>{c.name} </div>
                                    {c.current_step_start > startOfDay ? <Badge color="success"  style={{fontSize: 10}}>Moved Today</Badge> : ''}
                                </td>
                                <td>{c.current_category_name}</td>
                                <td>
                                    <div>{c.current_step_name} </div>
                                    <div>{moment.unix(c.current_step_start).format('MMM Do, YYYY')}</div>
                                </td>
                                <td>
                                    {c.last_step_name ? <div>{c.last_step_name}</div> : ''}
                                    {c.last_step_start ? <div>{moment.unix(c.last_step_start).format('MMM Do, YYYY')}</div> : ''}
                                </td>
                                <td>
                                    {c.last_note}
                                </td>
                                <td className="text-right">
                                    <span className={c.total ? 'text-success font-weight-bold' : ''}>{formatCurrency(c.total)}</span>
                                </td>
                                <td className="text-right">
                                    <button className="btn btn-sm btn-outline-success"><i className="fas fa-door-open" /> View</button>
                                </td>
                             
                            </tr>
                        )) : (
                            <tr>
                                <td><i className="fas fa-info-circle mr-2 text-info" /> No Activity Found</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                     
                    </tbody>
                </table>
            </div>
    
        </Card>
    )
}

export default DashboardOverview