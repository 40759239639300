import { useCallback,useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, Row, UncontrolledDropdown } from "reactstrap";

import formatObject from 'utils/formatObject'

import A from 'components/markup/links/A'
import Search from './Search';

import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { enableDialing } from 'store/functions/call_center';

// import AvatarImage from 'components/functional/images/AvatarImage';

import api from 'api';
import moment from 'moment';

import hasPrivilege from 'utils/hasPrivilege'

const NavbarDesktopHeaderTop = ({selected_division, divisions, socket, onSetDivision, viewing_user, DOMAINS}) => {

    // save value here because the viewing user is cached for 5 seconds on the server
    const [available, setAvailable] = useState(viewing_user.status_available)
    const [agentCanReceiveCalls, setAgentCanReceiveCalls] = useState(viewing_user.agent_can_receive_calls)
    const [agentIsInQueue, setAgentIsInQueue] = useState(viewing_user.agent_is_in_queue)
    const [canChangeState, setCanChangeStatus] = useState(true)

    const setReceivingQueueCalls = useCallback(async () => {
        setCanChangeStatus(false)
        enableDialing()
        const updated = await api.users.update(viewing_user._id, { agent_is_in_queue: !agentIsInQueue })
        setCanChangeStatus(true)
        if(updated.data) {
            setAgentIsInQueue(!agentIsInQueue)
        } else {
            toast.error(`Something went wrong changing your status, please try again`)
        }
    }, [agentIsInQueue, viewing_user._id])
   
    const setStatusAvailable = useCallback(async () => {
        setCanChangeStatus(false)

        // double check the users status has not changed anywhere
        const viewing_user = await api.auth.viewing_user()
        if(!viewing_user || (viewing_user && viewing_user.data.status_available !== available)) {
            if(!viewing_user) return toast.info('Please try again')
            return setAvailable(viewing_user.data.status_available)
        }

        const updated = await api.time_logs.log({date: moment().format('YYYY-MM-DD')})
        setCanChangeStatus(true)
        if(updated.data) {
            setAvailable(!available)
        } else {
            toast.error(`Something went wrong changing your status, please try again`)
        }
    }, [available])

    // this is fired directly to the user and not to a company
    const onCanReceiveCallsUpdated = useCallback((_user) => {
        if(_user._id === viewing_user._id) {
            setAgentCanReceiveCalls(_user.agent_can_receive_calls)
        }
    }, [viewing_user._id])
    
    const onUpdate = useCallback((data) => {
        if(data._id === viewing_user._id) {
            setAvailable(data.status_available)
            setAgentIsInQueue(data.agent_is_in_queue)
        }
    }, [viewing_user._id])

    useEffect(() => {
        socket.on('USERS.UPDATED', onUpdate)
        socket.on('AGENT_CAN_RECEIVE_CALLS', onCanReceiveCallsUpdated)

        return () => {
            socket.off('USERS.UPDATED', onUpdate)
            socket.off('AGENT_CAN_RECEIVE_CALLS', onCanReceiveCallsUpdated)
        }
    }, [onUpdate, onCanReceiveCallsUpdated, socket])

    return (

        <Container  fluid className='bg-white border-bottom'>

            <Row className="align-items-center py-2">

                <Col xs="5" className="align-self-center">
                    {/* <Link to="/referrals/add" className='btn btn-outline-success  '><i className="fas fa-plus mr-2" /> Add A Lead</Link> */}
                </Col>

                <Col xs="7"  className="text-right header-right align-self-center">

                    <Nav className="align-items-center ml-auto ml-md-0 pl-5 "  navbar>

                        <ul>
                            <li>

                                <UncontrolledDropdown >
                                    <DropdownToggle className="nav-link pr-0 cursor-pointer font-weight-bold" color="" tag="a">
                                        {selected_division.name ? selected_division.name : "All Divisions"} <i className="fas fa-caret-down ml-2 px-0 " />
                                    </DropdownToggle>

                                    <DropdownMenu end>
                                        <div className="dropdown-content">
                                            <DropdownItem  className="noti-title" header tag="div">
                                                <h6 className="text-overflow m-0">Selected Division</h6>
                                            </DropdownItem>

                                            {/* <DropdownItem onClick={() => onSetDivision({_id: 'all'})} >
                                                All Divisions
                                            </DropdownItem> */}

                                            {divisions.map(division => (
                                                <DropdownItem key={division._id} onClick={() => onSetDivision(division)} >
                                                    {division.name}
                                                </DropdownItem>
                                            ))}
                                        </div>
                                    </DropdownMenu>

                                </UncontrolledDropdown>
                            </li>


                            <li className='pl-3'>
                                <A href={DOMAINS.APP}>
                                    <i 
                                        id="archk-tooltip-nav-dashboard"
                                        data-tooltip-content={ `Main Dashboard`}
                                        data-tooltip-delay-show={1500}
                                        data-tooltip-variant={'success'}
                                        className="fas fa-door-open text-info navbar-block cursor-pointer" 
                                    />
                                    <Tooltip className="archk-react-tooltip" anchorId="archk-tooltip-nav-dashboard" />
                                </A>
                            </li>

                            <li>
                                {hasPrivilege('MATTERS.WRITE') || hasPrivilege('CONTACTS.WRITE') || hasPrivilege('TASKS.WRITE') || hasPrivilege('EVENTS.WRITE') ? (
                                    <UncontrolledDropdown >
                                        
                                        <DropdownToggle className="nav-link pr-0 cursor-pointer font-weight-bold" color="" tag="a">
                                            <i 
                                                id="archk-tooltip-nav-settings"
                                                data-tooltip-content={ `Add New`}
                                                data-tooltip-delay-show={1500}
                                                data-tooltip-variant={'success'}
                                                className="fas fa-plus text-muted navbar-block cursor-pointer" 
                                            />
                                            <Tooltip className="archk-react-tooltip" anchorId="archk-tooltip-nav-settings" />
                                        </DropdownToggle>

                                        <DropdownMenu end>
                                            <div className="dropdown-content">
    
                                                <DropdownItem  className="noti-title" header tag="div">
                                                    <h5 className="text-overflow m-0">Add Something</h5>
                                                </DropdownItem>
    
                                                <DropdownItem tag={Link} to={`/referrals/add`} >
                                                    New Referral
                                                </DropdownItem>

                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                ) : ''}                             
                            </li>

                            <li className='pl-3'>
                                <UncontrolledDropdown>
                                    <DropdownToggle className="nav-link pr-0 ml-3 cursor-pointer font-weight-bold text-capitalize" color="" tag="a">

                                    {formatObject(viewing_user).name() } <i className="fas fa-caret-down ml-2  px-0 " />
                                        
                                    </DropdownToggle>

                                    <DropdownMenu end>
                                        <div className="dropdown-content">
                                            <DropdownItem  className="noti-title" header tag="div">
                                                <h5 className="text-overflow m-0">My Account</h5>
                                            </DropdownItem>

                                            <DropdownItem tag={A} href={`${DOMAINS.APP}/settings`} >
                                                Settings
                                            </DropdownItem>
                                            
                                            <DropdownItem href={`${DOMAINS.AUTH}/logout`} >
                                                <span>Logout</span>
                                            </DropdownItem>
                                        </div>
                                    </DropdownMenu>

                                </UncontrolledDropdown>
                            </li>
                        </ul>

                    </Nav>

                </Col>

            </Row>

        </Container>

    )

}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
	    viewing_user: state.auth.viewing_user,
        selected_division: state.state.selected_division,
        divisions: state.divisions,
        socket: state.socket,

	};
};

export default connect(mapStateToProps, '')(NavbarDesktopHeaderTop);
