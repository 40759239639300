import { connect } from 'react-redux';

import NavbarMobile from './NavbarMobile'
import NavbarDesktop from './NavbarDesktop'


const Navbar = ({showMobileNav, toggleSidenav, viewing_user}) => {

	
	return showMobileNav ? (
		<NavbarMobile 
			toggleSidenav={toggleSidenav} 
		/>
	) : (
		<NavbarDesktop 
		/>
	)

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    device: state.device.info,
	};
};

export default connect(mapStateToProps, '')(Navbar);