import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Nav, NavItem, NavLink } from "reactstrap";


import Contact from './Contact';
import Number from './Number';
import User from './User';

import { toast } from 'react-toastify';

import { addCall } from 'store/functions/call_center';

const SidebarCallsModalAddCall = ({showModal, toggleModal, current_call}) => {

    const [type, setType] = useState('user')
    const [user, setUser] = useState(null)
    const [userNumber, setUserNumber] = useState(null)
    const [contact, setContact] = useState(null)
    const [contactNumber, setContactNumber] = useState(null)
    const [number, setNumber] = useState('')

    const onAddCall = useCallback(async () => {

        if(type === 'user') {
            if(!userNumber) {
                return toast.info('You must specify a user to call');
            }
        } else if(type === 'contact') {
            if(!contactNumber) {
                return toast.info('You must specify a contact with a phone number to call');
            }
        } else if(type === 'number') {
            if(!number) {
                return toast.info('You must specify a number to call');
            }
        }

        const added = await addCall({
            type,
            user,
            userNumber,
            contact,
            contactNumber,
            number,
            callSid: current_call.call_sid
        })

        if(added) {
            toggleModal()
        }

    }, [type, user, userNumber, contact, contactNumber, number, current_call.call_sid, toggleModal])

    useEffect(() => {

    }, [])

    return (

        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Add A Call</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body border-bottom bg-secondary">

                <Nav
                    className="nav-fill flex-column flex-sm-row"
                    id="tabs-text"
                    pills
                    role="tablist"
                >

                    <NavItem>
                        <NavLink
                            aria-selected={type === 'user'}
                            className={`mb-sm-3 mb-md-0 ${type === 'user' ? 'active' : ''}`}
                            onClick={e => setType('user')}
                            role="tab"
                        >
                            Add A User
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            aria-selected={type === 'contact'}
                            className={`mb-sm-3 mb-md-0 ${type === 'contact' ? 'active' : ''}`}
                            onClick={e => setType('contact')}
                            role="tab"
                        >
                            Add A Contact
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            aria-selected={type === 'number'}
                            className={`mb-sm-3 mb-md-0 ${type === 'number' ? 'active' : ''}`}
                            onClick={e => setType('number')}
                            role="tab"
                        >
                            Add A Number
                        </NavLink>
                    </NavItem>

                </Nav>

            </div>

            <div>

                {type === 'user' ? (
                    <User 
                        user={user}
                        setUser={setUser}
                        userNumber={userNumber}
                        setUserNumber={setUserNumber}
                    />
                ) : type === 'contact' ? (
                    <Contact 
                        contact={contact}
                        setContact={setContact}
                        contactNumber={contactNumber}
                        setContactNumber={setContactNumber}
                    />
                ) : type === 'number' ? (
                    <Number 
                        number={number}
                        setNumber={setNumber}
                    />
                ) : ''}

            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
                <button className="btn btn-success" onClick={onAddCall}>
                    <i className="fas fa-plus mr-2" /> Add Call
                </button>
            </div>

        </Modal>

    )
}

const mapStateToProps = state => {
    return {
	    current_call: state.call_center.current_call,
    };
};

export default connect(mapStateToProps, '')(SidebarCallsModalAddCall);