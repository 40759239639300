// https://www.twilio.com/docs/voice/sdks/javascript

import { connect } from 'react-redux';

import DeviceError from './DeviceError';
import IncomingCall from './IncomingCall';
import NotOnCall from './NotOnCall';
import NotSetup from './NotSetup';
import OnCall from './OnCall';
import Queue from './Queue';
import TransferStatus from './TransferStatus';


const Sidebar = ({call_center}) => {


    const needsUserConsent = call_center.browser_dialing === 'not_setup' || call_center.browser_dialing === 'loading';
    const deviceError = call_center.browser_dialing === 'error';
    const isTransferring = call_center.transfer_call.display_name;
    const incomingCall = call_center.incoming_call.number ? true : false;
    const currentCall = call_center.current_call.number ? true : false;

    let mainComponent;

    if(deviceError) {
        mainComponent = <DeviceError />
    } else if(needsUserConsent) {
        mainComponent = <NotSetup />
    } else if(incomingCall) {
        mainComponent = <IncomingCall />
    } else if(currentCall) {
        mainComponent = <OnCall />
    } else {
        mainComponent = <NotOnCall />
    }

    
    return (
        <div>
           
            {mainComponent}
            <Queue />
            {isTransferring ? <TransferStatus /> : ''}
        </div>
    )

}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
        matter: state.matters.selected_matter,
        call_center: state.call_center,
	    socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(Sidebar);
