import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import { Card, CardFooter, CardBody, Container, FormGroup, Input, Row, Col, CardHeader, CardTitle } from "reactstrap";
import { Link } from 'react-router-dom';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Circle from 'components/markup/loading/Circle';

import api from 'api';

import ReactSelect from 'components/functional/inputs/ReactSelect';
import ObjectFinder from 'components/system/Objects/Finder';

import PhoneInput from 'react-phone-input-2'

import moment from 'moment';
import { formatCurrency } from "utils/currency";

const baseState = {
    lead_source       : '',

    given_name        : '',
    family_name       : '',

    phone             : '',
    email             : '',
    
    notes             : '',
        
    address_line_1    : '',
    address_line_2    : '',
    city              : '',
    state             : '',
    postal_code       : '',
}

const DashboardOverview = ({ match }) => {

    const [data, setData] = useState(null)
    const [possibleSources, setPossibleSources] = useState(null)

    const [referral, setReferral] = useState(baseState)

    const onInputChange = useCallback((field, value) => {
        const r = JSON.parse(JSON.stringify(referral));
        r[field] = value;
        setReferral(r)
    }, [referral])

    // const save = useCallback(async () => {
    //     if(!referral.family_name && !referral.phone && !referral.email) {
    //         return toast.info(`When adding a referral either a last name, email address, or phone number must be specified.`)
    //     }

    //     const lead_source = possibleSources.length === 1 ? possibleSources[0] : referral.lead_source;
    //     if(!lead_source) return toast.info(`You must select the lead source you are adding this referral for.`)

    //     setLoading(true)
    //     const data = await api.affiliates.leadAdd({
    //         ...referral,
    //         lead_source,
    //         url: window.location.href
    //     })
    //     console.log(data)
    //     setLoading(false)

    //     if(!data.success) {
    //         return toast.error(`Something went wrong adding this referral, make sure phone numbers and email addresses are formatted correctly and try again.`)
    //     }

    //     toast.success(`Referral Added Successfully`);
    //     setReferral(baseState)

       
    // }, [possibleSources, referral])

    const fetchData = useCallback(async () => {
        const data = await api.affiliates.leadOverview(match.params.lead_id)

        if(data.data) setData(data.data)
        console.log(data)
    }, [match.params.lead_id])

    useEffect(() => {
        fetchData()
    }, [fetchData])
   
    if(!data) return <></>
  
    return (

        <>

            <Helmet>
                <title>{`Add Referral`}</title>
                <meta name="description" content="Add Referral" />
            </Helmet>


            <HeaderNavigation 
                title="Referrals"
                description="View Entry"

            />

            <Container className="ful mt--4 pt-4" fluid>

                <Row>
                    <Col md={5}>
                        <h2><i className="fas fa-user mr-2 text-info" /> Entry Information</h2>
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0"> Lead Details Provided</CardTitle>
                            </CardHeader>

                            <CardBody>

                                        <p className="text-sm font-weight-bold text-dark mb-0">Name </p>
                                        <p className="text-sm mb-3 mt--2">{data.lead.display_name} {data.lead.duplicate ? ' - DUPLICATE' : ''}</p>
                                       
                                        <p className="text-sm font-weight-bold text-dark mb-0">Email </p>
                                        <p className="text-sm mb-3 mt--2">{data.lead.email}</p>
                                      
                                        <p className="text-sm font-weight-bold text-dark mb-0">Phone </p>
                                        <p className="text-sm mb-3 mt--2">{data.lead.phone}</p>

                                        <p className="text-sm font-weight-bold text-dark mb-0">Type </p>
                                        <p className="text-sm mb-3 mt--2 text-capitalize">{data.lead.type}</p>
                                      
                                        <p className="text-sm font-weight-bold text-dark mb-0">Lead Source </p>
                                        <p className="text-sm mb-3 mt--2">{data.lead.lead_source}</p>
                                    
                                        <p className="text-sm font-weight-bold text-dark mb-0">Created </p>
                                        <p className="text-sm mb-3 mt--2">{moment.unix(data.lead.created_at).format('MM/DD/YYYY h:mmA')}</p>


                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={7}>
                        <h2>Open Referral Matters ({data.matters.length})</h2>
                        {data.matters.length ? data.matters.map(m => (
                            <Card>
                               <CardHeader>
                                   <CardTitle className="mb-0">{m.name}</CardTitle>
                               </CardHeader>

                               <CardHeader>
                                    <Row>
                                        <Col md={6}>
                                            <p className="text-sm font-weight-bold text-dark mb-0">Step Last Moved </p>
                                            <p className="text-sm mb-3 mt--2">{moment.unix(m.current_step_start).format('MMM Do, YYYY h:mm A')}</p>

                                            <p className="text-sm font-weight-bold text-dark mb-0">Current Step </p>
                                            <p className="text-sm mb-3 mt--2">{m.current_step_name }</p>
                                        
                                            <p className="text-sm font-weight-bold text-dark mb-0">Current Step Category </p>
                                            <p className="text-sm mb-3 mt--2">{m.current_category_name}</p>
                                        </Col>
                                        <Col md={6}>
                                            <p className="text-sm font-weight-bold text-dark mb-0">Matter Value </p>
                                            <p className="text-sm mb-3 mt--2">{formatCurrency(m.total) }</p>
                                        
                                            <p className="text-sm font-weight-bold text-dark mb-0">Previous Step </p>
                                            <p className="text-sm mb-3 mt--2">{m.last_step_name ? m.last_step_name : '-' }</p>
                                        
                                            <p className="text-sm font-weight-bold text-dark mb-0">Previous Step Category </p>
                                            <p className="text-sm mb-3 mt--2">{m.last_category_name ? m.last_category_name : '-'}</p>
                                        </Col>
                                    </Row>
                               </CardHeader>
                              
                               <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Associate Name</th>
                                            <th>Associate Role</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {m.roles.length ? m.roles.map((mm, i) => (
                                            <tr key={i}>
                                                <td>{mm.name}</td>
                                                <td>{mm.role}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>No Roles Found</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                               </table>

                               <CardFooter>
                                    <p className="text-sm mb-0"><b>Last Note: </b>{m.last_note ? m.last_note : '-'}</p>
                               </CardFooter>

                               <CardFooter className="text-right">
                                    <Link className="btn btn-sm btn-success" to={`/matters/${m._id}`}>View Matter</Link>
                               </CardFooter>

                           </Card>

                        )) : (
                            <div>No matters found</div>
                        )}
                    </Col>

                </Row>

             
                   
            
            </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    socket: state.socket,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(DashboardOverview);
