import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Card, CardBody, CardHeader, CardTitle, Container } from "reactstrap";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';

const DashboardOverview = ({selected_division}) => {
  
    return (

        <>

            <Helmet>
                <title>{`Resources`}</title>
                <meta name="description" content="Resources" />
            </Helmet>

            <HeaderNavigation 
                title="Resources"
                description="Division Affiliate Materials"
                leftColSize={4}
            />

            <Container className="ful mt--4 pt-4" fluid>

                <Card>
                    <CardHeader>
                        <CardTitle className="mb-0">My Account Resources</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm mb-0"><i className="fas fa-info-circle mr-2 text-info" /> Your account does not have any resources currently associated with it.</p>
                    </CardBody>
                </Card>

            </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    device: state.device,
	    socket: state.socket,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(DashboardOverview);
