import { FormGroup, Input } from "reactstrap";

const ContactsUpdate = ({onInputChange, contact}) => {
    return (
        <div>
            <FormGroup>
                <label className="form-control-label">First Name</label>
                <Input 
                    type="text"
                    value={contact.given_name}
                    onChange={e => onInputChange('given_name', e.target.value)}
                />
            </FormGroup>

            <FormGroup>
                <label className="form-control-label">Last Name</label>
                <Input 
                    type="text"
                    value={contact.family_name}
                    onChange={e => onInputChange('family_name', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <label className="form-control-label">Middle Name</label>
                <Input 
                    type="text"
                    value={contact.middle_name}
                    onChange={e => onInputChange('middle_name', e.target.value)}
                />
            </FormGroup>
        </div>
    )

}

export default ContactsUpdate;