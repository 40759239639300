/*
Documentation

this page holds all of our layouts and functions
that need to fire before the app runs

*/

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Dashboard from 'views/dashboard/layout';
import Redirect from 'views/redirect/layout';

import FailedConnection from 'views/errors/pages/errors/FailedConnection';
import Unauthorized from './views/unauthorized';

import SystemAlert from 'components/markup/system/Alert'
import SystemLoader from 'components/markup/system/Loader'
import StandardLoader from 'components/markup/system/StandardLoader'

import LoggedInRoute from 'components/system/Routing/LoggedInRoute'
import { ToastContainer } from 'react-toastify';

import init from './init'


class App extends Component {

    state = {
        unauthorized: false,
        loaded: false,
        error: false
    };

    componentDidMount = async () => {

        const loadedSuccessfully = await init();
        this.setState({loaded: true, error: !loadedSuccessfully, unauthorized: loadedSuccessfully === 'unauthorized'})

    }

    componentDidCatch = (e) => {

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    render() {

        if(this.state.shouldRedirect) {
            return window.location.href = this.state.shouldRedirect
        }

        if(!this.state.loaded) {
            return <div></div>
        }

        if(this.state.error) {
            return <FailedConnection />
        }
        
        if(this.state.unauthorized) {
            return <Unauthorized />
        }

        return (

            <>

                <SystemAlert />
                <SystemLoader />
                <StandardLoader />
                <ToastContainer 
                    autoClose={3000} 
                />

                <BrowserRouter>
                    <Switch>
                       
                        <Route path="/redirect" render={props => 
                            <LoggedInRoute>
                                <Redirect {...props} /> 
                            </LoggedInRoute>
                        } />
                        <Route path="/" render={props => 
                            <LoggedInRoute>
                                <Dashboard {...props} /> 
                            </LoggedInRoute>
                        } />

                    </Switch>
                </BrowserRouter>

            </>

        )

    }

}

export default App
