import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import Circle from 'components/markup/loading/Circle';

import api from 'api';

import Categories from './Categories';
import RecentActivity from './RecentActivity';

import { formatCurrency } from "utils/currency";

import addReferral from './addReferral.svg';
import downloadResources from './downloadResources.svg';
import requestUpdate from './requestUpdate.svg';

const DashboardOverview = ({selected_division}) => {

    const [data, setData] = useState(null)
    const [total, setTotal] = useState(0)

    const fetchData = useCallback(async () => {
        setData(null);

        const result = await api.affiliates.overview(selected_division._id )
        if(!result.data) return toast.error(`Something went wrong, please refresh your page and try again.`)

        let total = 0;

        result.data.categories.forEach(c => {
            c.categories.forEach(cc => {
                total += cc.value;
            })
        })

        setTotal(total)
        setData(result.data)
    }, [selected_division._id])

    useEffect(() => {
        fetchData();

        document.body.classList.add('bg-secondary');
        return () => document.body.classList.remove('bg-secondary');
    }, [fetchData])

    if(!data) return <Circle className="py-6" />

  
    return (

        <>

            <Helmet>
                <title>{`Affiliate Dashboard`}</title>
                <meta name="description" content="Affiliate Dashboard" />
            </Helmet>

            <HeaderNavigation 
                title="My Pipeline"
                description="Data Overview"
                leftColSize={4}
            />

            <Container className="ful mt--4 pt-4" fluid>

            <Card className="rounded mb-4">
                    <CardBody className="bg-gradient-success rounded py-4">
                        <h2 className="mb-0 text-white px-4">Total Pipeline Value: <span className="float-right">{formatCurrency(total)}</span></h2>
                    </CardBody>
                </Card>

                <Row>
                    <Col md={4} style={{maxWidth: 200}}>
                        <Link to="/referrals/add">
                            <Card className="text-center">
                                <CardHeader>
                                    <CardTitle className="mb-0 text-center"> Add Referral</CardTitle>
                                </CardHeader>
                                <CardBody className="p-0">
                                    <img src={addReferral} style={{maxWidth: '130px', padding: 0}} alt="..." />
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>

                    <Col md={4} style={{maxWidth: 200}}>
                        <Card className="text-center">
                            <CardHeader>
                                <CardTitle className="mb-0 text-center"> Request Update</CardTitle>
                            </CardHeader>
                            <CardBody className="p-0">
                                <img src={requestUpdate} style={{maxWidth: '76px'}} alt="..." />
                            </CardBody>
                        </Card>
                    </Col>

                    <Link to="/referrals/add">
                        <Col md={4} style={{maxWidth: 200}}>
                            <Card className="text-center">
                                <CardHeader>
                                    <CardTitle className="mb-0 text-center"> Download Resources</CardTitle>
                                </CardHeader>
                                <CardBody className="p-0">
                                    <img src={downloadResources} style={{maxWidth: '170px'}} alt="..." />
                                </CardBody>
                            </Card>
                        </Col>
                    </Link>
                </Row>

               
                
                {/* <Billing data={data} /> */}
                <Categories data={data} />
                <RecentActivity data={data} />
                {/* <MatterDocuments data={data} />
                <ESignatures data={data} />
                <TodaysLeads data={data} /> */}

                <div className="pb-6" />
            
            </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    device: state.device,
	    socket: state.socket,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(DashboardOverview);
