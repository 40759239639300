import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import api from 'api';

const NavbarDesktopHeaderBottomNotifications = ({ socket }) => {

    const [count, setCount] = useState(null)
    const [pulse, setPulse] = useState(false)

    const color = count === 0 ? 'success' : count <= 49 ? 'info' : 'danger';

    const onNotificationChange = useCallback((params) => {
        setCount(count + params.count)
        if(params.count === 1) {
            setPulse(true)
            setTimeout(() => {
                setPulse(false)
            }, 2000)
        }
    }, [count])

    const fetchNotifications = useCallback(async () => {
        const result = await api.notifications.count()
        if(result.success) setCount(result.data.count)
    }, [])

    useEffect(() => {
        fetchNotifications()
    }, [fetchNotifications])
  
    useEffect(() => {
        socket.on('NOTIFICATIONS.CHANGED', onNotificationChange)

        return () => {
            socket.off('NOTIFICATIONS.CHANGED', onNotificationChange)
        }
    }, [onNotificationChange, socket])

    return (

        <Link to="/notifications">
            <i className={`ml-0 text-${color} fas fa-bell  ${pulse ? 'pulse' : ''}`} /> {' '}
            {count === null ? '' : (
                <span 
                    style={styles.notification} 
                    className={` notification notification-${color}`}
                >
                    {count >= 99 ? '99+' : count}
                </span>
            )}
        </Link>

    )

}

const styles = {
    notification: {
        display: 'inline-block',
        position: 'relative',
        width: 35,
        textAlign: 'center'
    }
}

const mapStateToProps = state => {
	return {
        socket: state.socket,
	};
};

export default connect(mapStateToProps, '')(NavbarDesktopHeaderBottomNotifications);
