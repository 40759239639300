import { useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import NameCircle from 'components/markup/misc/NameCircle';
import CountUp from 'components/markup/time/CountUp';

import ModalToggler from 'components/functional/modals/Toggler'
import CallContact from 'components/system/CallContact'

const CallsNotOnCall = ({selected_matter, selected_contact}) => {

    const hasContact = selected_contact._id ? true : false;
    const hasMatter = selected_matter._id ? true : false;

    const [lastCall] = useState(Math.floor(new Date() / 1000))

   return (
        <div>
            <div className='bg-gradient-dark text-center archk-call pt-4 pb-2 cursor-pointer'>
                <div className='pt-3'>
                    <NameCircle className="z-depth-2" width={70} contact={{given_name: 'N', family_name: 'A'}}  />{' '}
                    <p className='text-sm mb-0 mt-3 text-white font-weight-bold'><i className="fas fa-phone-volume mr-2 text-danger" /> Not On Call</p>
                    <p className='text-sm mb-3 text-muted'>Last Call: <CountUp start={lastCall} /> Ago</p>
                </div>
            </div>

            <div className='text-center archk-call pt-0 pb-3  border-bottm bg-secondary border-top' style={{overflow: 'hidden'}}>
                <div className="archk-on-call-wapper archk-call-buttons text-centr   mt-4 mb-2 px-4">
                    <Row>
                        {hasMatter || hasContact ? (
                            <Col md={6} className='text-center'>
                                <ModalToggler component={CallContact} matter={selected_matter._id} contact={selected_contact._id}>
                                    <button className='btn btn-block btn-sm btn-info' >
                                        Call <i className="fas fa-user ml-2" />
                                    </button>
                                </ModalToggler>
                            </Col>
                        ) : ''}
                        
                        <Col md={hasContact || hasMatter ? 6 : 12} className='text-center'>
                            <ModalToggler component={CallContact} >
                                <button  className='btn btn-block btn-sm btn-success' >
                                    Call <i className="fas fa-hashtag" />
                                </button>
                            </ModalToggler>
                        </Col>
                    </Row>

                </div>
            </div>
        </div>
   )
}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
        selected_matter: state.matters.selected_matter,
        selected_contact: state.contacts.selected_contact,
	};
};

export default connect(mapStateToProps, '')(CallsNotOnCall);
