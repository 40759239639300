import { FormGroup, Input, Row, Col } from "reactstrap";
import { connect } from 'react-redux'

import ObjectFinder from 'components/system/Objects/Finder';
import Circle from 'components/markup/loading/Circle'

const ContactsUpdate = ({contactTypes, onContactTypeChange, viewing_user, contact}) => {

    return (
        <div>

            <Row>
                {!contactTypes ? (
                    <Circle />
                ) : !Object.keys(contactTypes).length ? (
                    <p></p>
                ) :Object.keys(contactTypes).map(division => viewing_user.divisions.includes(division) ?  (
                    <Col lg={3} key={division}>
                        <h3><ObjectFinder collection="divisions" _id={division} /></h3>

                        {contactTypes[division].map(type => (
                            <div key={type._id}>
                                <label className="custom-toggle">
                                    <input  
                                        type="checkbox" 
                                        checked={contact.contact_types.includes(type._id)}
                                        onChange={(e) => {
                                            onContactTypeChange(type._id)
                                        }}
                                    />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Yes"
                                    />
                                </label>    
                                <span style={{position: 'relative', top: -7, left: 5}}>{type.name}</span>
                            </div>
                        ))}

                    </Col>
                ) : '')}
            </Row>

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(ContactsUpdate);
