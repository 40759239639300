import { Row, Col } from 'reactstrap';

const InternalSidebar = ({title, tabs, tab, setTab, className, children, width}) => (
    <Row className="archk-internal-sidebar">
        <div className={`col-auto archk-internal-sidebar-col-left ${className ? className : ''}`} style={{width: width ? width : 150}}>
            <h2>{title}</h2>

            <ul>
                {tabs.map((t, i) => (
                    <li onClick={() => setTab(t.value)} key={i} className={t.value === tab ? 'active' : ''}>{t.name}</li>
                ))}
            </ul>

        </div>
        <Col>
            {children}
        </Col>

    </Row>
)

export default InternalSidebar;