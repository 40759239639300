import { useCallback, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";
import { formatCurrency } from "utils/currency";

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';

import api from 'api';
import moment from 'moment';


const DashboardOverview = ({ match }) => {

    const [data, setData] = useState(null)

    const fetchData = useCallback(async () => {
        const data = await api.affiliates.matterOverview(match.params.matter_id)

        if(data.data) setData(data.data)
        console.log(data)
    }, [match.params.matter_id])

    useEffect(() => {
        fetchData()
    }, [fetchData])
   
    if(!data) return <></>
  
    return (

        <>

            <Helmet>
                <title>{data.name}</title>
                <meta name="description" content={data.name} />
            </Helmet>


            <HeaderNavigation 
                title="Matters"
                description="View Matters Updates"
                actionComponent={(
                    <div><button className="btn btn-success">Request Update</button></div>
                )}

            />

            <Container className="ful mt--4 pt-4" fluid>

                <Row>
                    <Col md={6}>
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Matter Info</CardTitle>
                            </CardHeader>

                            <CardBody>
                                <p className="text-sm font-weight-bold text-dark mb-0">Name </p>
                                <p className="text-sm mb-3 mt--2">{data.name}</p>
                            
                                <p className="text-sm font-weight-bold text-dark mb-0">Value </p>
                                <p className="text-sm mb-3 mt--2">{formatCurrency(data.total)}</p>
                             
                                <p className="text-sm font-weight-bold text-dark mb-0">Current Step </p>
                                <p className="text-sm mb-3 mt--2">{data.current_step_name ? data.current_step_name : '-'}</p>
                              
                                <p className="text-sm font-weight-bold text-dark mb-0">Current Step Category</p>
                                <p className="text-sm mb-3 mt--2">{data.current_category_name ? data.current_category_name : '-'}</p>
                              
                                <p className="text-sm font-weight-bold text-dark mb-0">Previous Step </p>
                                <p className="text-sm mb-3 mt--2">{data.last_step_name ? data.last_step_name : '-'}</p>
                              
                                <p className="text-sm font-weight-bold text-dark mb-0">Previous Step Category</p>
                                <p className="text-sm mb-3 mt--2">{data.last_category_name ? data.last_category_name : '-'}</p>
                               
                                <p className="text-sm font-weight-bold text-dark mb-0">Created At</p>
                                <p className="text-sm mb-3 mt--2">{moment.unix(data.created_at).format('MMM Do, YYYY h:mm A')}</p>
                                       
                            </CardBody>

                        </Card>
                       
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Matter Associates</CardTitle>
                            </CardHeader>

                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Associate Name</th>
                                            <th>Associate Role</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {data.roles.length ? data.roles.map((mm, i) => (
                                            <tr key={i}>
                                                <td>{mm.name}</td>
                                                <td>{mm.role}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>No Roles Found</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                               </table>

                        </Card>
                     
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Timeline</CardTitle>
                            </CardHeader>

                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Step</th>
                                            <th>Category</th>
                                            <th>Associate</th>
                                            <th className="text-right">Date</th>
                                        </tr>
                                    </thead>
     
                                    <tbody>
                                        {data.dates.length ? data.dates.map((mm, i) => (
                                            <tr key={i}>
                                                <td>{mm.workflow_step}</td>
                                                <td>{mm.workflow_step_category}</td>
                                                <td>{mm.user}</td>
                                                <td className="text-right">{moment.unix(mm.timestamp).format('MMM Do, YYYY h:mm A')}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>No Dates Found</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                               </table>

                        </Card>
                    </Col>

   
                    <Col md={6}>
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0">Matter Notes ({data.notes.length})</CardTitle>
                            </CardHeader>

                            {data.notes.length ? data.notes.map(d => (
                                <div>
                                    <CardFooter>
                                        <p className="text-sm mb-0">
                                            <div className="text-sm" dangerouslySetInnerHTML={{__html: d.value}} />
                                        </p>
                                    </CardFooter>
                                    <CardFooter className="bg-secondary">
                                        <p className="text-sm mb-0"><i className="fa-solid fa-arrow-turn-up" style={{transform: 'rotate(90deg)'}} /> By {d.user} - {moment.unix(d.created_at).format('MMM Do, YYYY h:mm A')}</p>
                                    </CardFooter>
                                </div>
                            )) : (
                                <CardBody>
                                    <p className="text-sm mb-0">No notes have been found for this matter.</p>
                                </CardBody>
                            )}
                            <CardFooter className="text-right">
                                <p className="text-sm mb-0">**The above table shows up to the last 50 notes associates have left on this matter.</p>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>

                {/* <Row>
                    <Col md={5}>
                        <h2><i className="fas fa-user mr-2 text-info" /> Entry Information</h2>
                        <Card>
                            <CardHeader>
                                <CardTitle className="mb-0"> Lead Details Provided</CardTitle>
                            </CardHeader>

                            <CardBody>

                                        <p className="text-sm font-weight-bold text-dark mb-0">Name </p>
                                        <p className="text-sm mb-3 mt--2">{data.lead.display_name} {data.lead.duplicate ? ' - DUPLICATE' : ''}</p>
                                       
                                        <p className="text-sm font-weight-bold text-dark mb-0">Email </p>
                                        <p className="text-sm mb-3 mt--2">{data.lead.email}</p>
                                      
                                        <p className="text-sm font-weight-bold text-dark mb-0">Phone </p>
                                        <p className="text-sm mb-3 mt--2">{data.lead.phone}</p>

                                        <p className="text-sm font-weight-bold text-dark mb-0">Type </p>
                                        <p className="text-sm mb-3 mt--2 text-capitalize">{data.lead.type}</p>
                                      
                                        <p className="text-sm font-weight-bold text-dark mb-0">Lead Source </p>
                                        <p className="text-sm mb-3 mt--2">{data.lead.lead_source}</p>
                                    
                                        <p className="text-sm font-weight-bold text-dark mb-0">Created </p>
                                        <p className="text-sm mb-3 mt--2">{moment.unix(data.lead.created_at).format('MM/DD/YYYY h:mmA')}</p>


                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={7}>
                        <h2>Open Referral Matters ({data.matters.length})</h2>
                        {data.matters.length ? data.matters.map(m => (
                            <Card>
                               <CardHeader>
                                   <CardTitle className="mb-0">{m.name}</CardTitle>
                               </CardHeader>

                               <CardHeader>
                                    <Row>
                                        <Col md={6}>
                                            <p className="text-sm font-weight-bold text-dark mb-0">Step Last Moved </p>
                                            <p className="text-sm mb-3 mt--2">{moment.unix(m.current_step_start).format('MMM Do, YYYY h:mm A')}</p>

                                            <p className="text-sm font-weight-bold text-dark mb-0">Current Step </p>
                                            <p className="text-sm mb-3 mt--2">{m.current_step_name }</p>
                                        
                                            <p className="text-sm font-weight-bold text-dark mb-0">Current Step Category </p>
                                            <p className="text-sm mb-3 mt--2">{m.current_category_name}</p>
                                        </Col>
                                        <Col md={6}>
                                            <p className="text-sm font-weight-bold text-dark mb-0">Matter Value </p>
                                            <p className="text-sm mb-3 mt--2">{formatCurrency(m.total) }</p>
                                        
                                            <p className="text-sm font-weight-bold text-dark mb-0">Previous Step </p>
                                            <p className="text-sm mb-3 mt--2">{m.last_step_name ? m.last_step_name : '-' }</p>
                                        
                                            <p className="text-sm font-weight-bold text-dark mb-0">Previous Step Category </p>
                                            <p className="text-sm mb-3 mt--2">{m.last_category_name ? m.last_category_name : '-'}</p>
                                        </Col>
                                    </Row>
                               </CardHeader>
                              
                               <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Associate Name</th>
                                            <th>Associate Role</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {m.roles.length ? m.roles.map((mm, i) => (
                                            <tr key={i}>
                                                <td>{mm.name}</td>
                                                <td>{mm.role}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>No Roles Found</td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </tbody>
                               </table>

                               <CardFooter>
                                    <p className="text-sm mb-0"><b>Last Note: </b>{m.last_note ? m.last_note : '-'}</p>
                               </CardFooter>

                               <CardFooter className="text-right">
                                    <Link className="btn btn-sm btn-success" to={`/matters/${m._id}`}>View Matter</Link>
                               </CardFooter>

                           </Card>

                        )) : (
                            <div>No matters found</div>
                        )}
                    </Col>

                </Row> */}

             
                   
            
            </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    socket: state.socket,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(DashboardOverview);
