import { useState, useEffect } from 'react'
import formatText from 'utils/formatText'

const CountUp = ({start}) => {

    const [now, setNow] = useState(Math.floor(new Date() / 1000))

    useEffect(() => {
        let interval = setInterval(() => {
            setNow(Math.floor(new Date() / 1000))
        }, [])

        return () => {
            clearInterval(interval)
        }
    })

    return (
        formatText(now - start).secondsToTime()
    )
}

export default CountUp;