// https://dribbble.com/BalkanBrothers/projects/379629-CRM-Dashboard#attachment-587135

import LOGO from 'assets/img/brand/Intuidesk.png'
import LOGO_WHITE from 'assets/img/brand/Intuidesk.png'

let keys = {
    LOGO,
    LOGO_WHITE,
    COMPANY: 'IntuiDesk',
};

const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME === 'affiliate.intuidesk.com') {

    keys = {

        ...keys,

        SYSTEM_API_KEY        : 'architeck-1957163548',
        API_URL               : 'https://api.intuidesk.com',
        PROCESSING_URL        : 'https://processing.intuidesk.com',
        SOCKET_URL            : 'https://sockets.intuidesk.com',

    }

//using staging keys
} else if(HOSTNAME === 'staging.affiliate.intuidesk.com') {

    keys = {

        ...keys,

        SYSTEM_API_KEY        : 'architeck-4184167461',
        API_URL               : 'https://staging.api.intuidesk.com',
        PROCESSING_URL        : 'https://staging.processing.intuidesk.com',
        SOCKET_URL            : 'https://staging.sockets.intuidesk.com',

    }

//using development keys
} else {

    keys = {

        ...keys,

        SYSTEM_API_KEY        : 'architeck-',
        API_URL               : 'http://localhost:5001',
        PROCESSING_URL        : 'http://localhost:5002',
        SOCKET_URL            : 'http://localhost:5003',

    }

}
export default keys
