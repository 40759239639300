import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, Row, UncontrolledDropdown } from "reactstrap";
import hasPrivilege from 'utils/hasPrivilege'

import Notification from './Notifications'

const NavbarDesktopHeaderBottom = ({ selected_division }) => {

    const location = useLocation();

    return (

        <div className="border-bottom border-top bg-white header-bottom">
            <Container fluid>
                <Row className="align-items-center">

                    <Col xs="8" >
                        <Nav className="align-items-center"  navbar>

                            <ul>
                              
                                <li className={location.pathname === '/' ? 'active' : ''}>
                                    <Link to="/" >Overview</Link>
                                </li>
                               
                                <li className={location.pathname === '/work_center' ? 'active' : ''}>
                                    <Link to="/work_center" >Work Center</Link>
                                </li>

                                {hasPrivilege('EVENTS.READ') ? (
                                    <li className={location.pathname === '/calendar' ? 'active' : ''}>
                                        <Link to="/calendar" >Calendar</Link>
                                    </li>
                                ) : ''}
                               
                                <li className={location.pathname === '/today' ? 'active' : ''}>
                                    <Link to="/" >Today</Link>
                                </li>
                                <li className={location.pathname.includes('/tasks') ? 'active' : ''}>
                                    <Link to="/tasks" >My Tasks</Link>
                                </li>

                                {/* <li className={location.pathname.includes('matasdfasdfters') ? 'active' : ''}>
                                    <Link to="/matters" >KPIs</Link>
                                </li> */}
                           
                                <UncontrolledDropdown nav  className={location.pathname.includes('more') ? 'active' : ''} >
                                    <DropdownToggle 
                                        className="nav-link pr-0 py-0 cursor-pointer font-weight-bold" 
                                        color="" 
                                        tag="a"
                                    >
                                        More <i className="fas fa-caret-down " />
                                    </DropdownToggle>

                                    <DropdownMenu>
                                        <div className="dropdown-content">
                                            <DropdownItem  className="noti-title" header tag="div">
                                                <h5 className="text-overflow m-0">View All</h5>
                                            </DropdownItem>

                                            {/* <DropdownItem tag={Link} to={'/time'} >
                                                <span className=''><i className="fas fa-clock" /> Time Off</span>
                                            </DropdownItem> */}
                                            
                                            <DropdownItem tag={Link} to={'/matter_progress'} >
                                                <span className=''><i className="fas fa-chart-line" /> Activity Reports</span>
                                            </DropdownItem>
                                          
                                            <DropdownItem tag={Link} to={'/client_hotline'} >
                                                <span className=''><i className="fa-solid fa-search" /> Advanced Search</span>
                                            </DropdownItem>

                                            <DropdownItem tag={Link} to={'/matter_progress'} >
                                                <span className=''><i className="fa-solid fa-bars-progress" /> Progress Logs</span>
                                            </DropdownItem>
                                           
                                            {/* <DropdownItem tag={Link} to={'/work_center'} >
                                                <span className=''><i className="fa-solid fa-bars-progress" /> Work Center</span>
                                            </DropdownItem> */}
                                            
                                        </div>
                                    </DropdownMenu>

                                </UncontrolledDropdown>

                            </ul>
                        </Nav>                            
                    </Col>

                    <Nav className="align-items-center ml-auto header-right-side"  navbar>
                        <ul>
                          
                            <li>
                                <Notification />
                            </li>

                            {/* <li>
                                <Link to="/leads">
                                    <i className="ml-0 body-color fas fa-mobile text-warning mr-1" /> <span style={styles.notification} className="notification notification-warning">14</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/hotline">
                                    <i className="ml-0 body-color fas fa-headset text-warning mr-1" /> <span style={styles.notification} className="notification notification-warning">14</span>
                                </Link>
                            </li> */}
                        </ul>
                    </Nav>                            

                </Row>
            </Container>           
        </div>

    )

}

const mapStateToProps = state => {
	return {
        viewing_user: state.auth.viewing_user,
        selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(NavbarDesktopHeaderBottom);
