const DeviceError = () => (
    <div className='bg-gradient-dark text-center archk-call pt-4 pb-2 cursor-pointer' >
        <div className='pt-3'>

            <i className="fa-solid fa-phone-slash mr-2 display-2 text-danger" />
            
            <p className='text-sm my-4 text-white font-weight-bold px-3'>
                <b className='text-uppercase text-underlin text-danger'>Device Error:</b> Refresh Your Page To Continue Making Calls
            </p>
            
        </div>
    </div>
)

export default DeviceError