import { useState } from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { Card, Container } from "reactstrap";

import Table from 'components/functional/tables/Standard';

import HeaderNavigation from 'components/markup/layout/HeaderNavigation';

import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import ObjectFinder from 'components/system/Objects/Finder';

import api from 'api';

const MattersAll = ({match, viewing_user, selected_division}) => {

    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ err, setErr ] = useState(false)

    // helper for this.downloadCSV and this.query
    const fetchData = (params, isCSV) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(!params.filter) params.filter = {};

        params.filter.deleted = false;

        const query = await api.affiliates.matterSearch(selected_division._id, { ...params, isCSV })

        if(query.data) return resolve(query.data)
        setErr(query.message)
    })

    // run query to get data from the server
    const query = async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }

    const columns = [
        {
            dataField: "given_name",
            text: 'Name',
            headerFormatter: () => {
                return <div><div>Matter</div></div>
            },
            formatter: (cell, row) => {

                if(row.name === 'no data') return (
                    <div style={{minHeight: 150}}>No Data To Show</div>
                )
                return (
                    <div>
                        <div><Link to={`/matters/${row._id}`} className="text-dark font-weight-bold">{row.name}</Link></div>
                    </div>
                )
            },
        },
        {
            dataField: "current_step_category",
            text: 'Category',
            headerFormatter: () => (
                <div>Category</div>
            ),
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div>
                        <div>{row.workflow_step_category ? <ObjectFinder collection="workflow_step_categories" _id={row.workflow_step_category} /> : 'No Category'}</div>
                    </div>
                )
            )
        },
        {
            dataField: "current_step_start",
            text: 'Current Step',
            formatter: (cell, row) => (
                row.name === 'no data' ? '' : (
                    <div>
                        <div>
                            {
                                row.current_step_name ? row.current_step_name : 
                                <span>
                                    <i className="fas fa-exclamation-triangle mr-2 text-warning " /> None Assigned
                                </span>
                            }
                        </div>
                        {row.current_step_start ? <div>{moment.unix(row.current_step_start).format('MM/DD/YYYY')}</div> : ''}
                    </div>
                )
            )
        },
        {
            dataField: "current_step_percentage",
            text: 'Step %',
            formatter: (cell, row) => {
                if(row.name === 'no data' ) return ''
                let className = "";
                if(row.current_step_percentage >= 0 && row.current_step_percentage < 19.99) className = 'text-danger'
                if(row.current_step_percentage >= 20 && row.current_step_percentage < 49.99) className = 'text-warning'
                if(row.current_step_percentage >= 50 && row.current_step_percentage < 97.99) className = 'text-info'
                if(row.current_step_percentage >= 98) className = 'text-success'

                return (
                    <div>
                       
                        <span className={className}>{row.current_step_percentage ? row.current_step_percentage + '%' : 0 + '%'}</span>
                    </div>
                )
            }
        },
        {
            dataField: "last_note",
            text: 'Last Note',
            formatter: (cell, row) => {
                if(row.name === 'no data' ) return ''
                return row.last_note
            }
        },

       
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right', width: 140},
            text: '',
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                        <TableIcons
                            icons={[
                                {
                                    icon: 'fas fa-external-link-alt',
                                    color: 'success', 
                                    wrapper: Link, 
                                    to: `/matters/${row._id}`,
                                },
                               
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(err) return <ApiError err={err} className="mx-4 py-3" />

    return (

        <>

        <Helmet>
            <title>Search Matters</title>
            <meta name="description" content="Search Matters" />
        </Helmet>

        <HeaderNavigation 
            title={'Matters'}
            description={"View All"}
        />

        <Container fluid>
    
            <Card className="card-color card-primary table-fixed table-no-stripes">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search Matters..."
                    query={query}
                    rowClasses={(row) => {
                        if(!row.roles) return ''
                        const isAssignedOnRole = row.roles.some(r => r.user === viewing_user._id)
                        return isAssignedOnRole ? 'row-mine' : '';
                    }}
                    columns={columns}
                    data={tableData.data && tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}

                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

        </Container>

        </>
    )

}

const mapStateToProps = state => {
	return {
	    selected_division: state.state.selected_division,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(MattersAll);
