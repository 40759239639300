import PhoneInput from 'react-phone-input-2'

               


import { connect } from 'react-redux';

import SearchCollections from "components/system/Search/Collections";

const SidebarCallsModalAddCall = ({number, setNumber}) => {


    return (
        <div className='modal-body py-4'>
            <PhoneInput
                country={'us'}
                specialLabel=""
                value={number}
                onChange={phone => setNumber(phone)}
            /> 
        </div>

    )
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(SidebarCallsModalAddCall);