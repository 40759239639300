import { connect } from 'react-redux';

import NameCircle from 'components/markup/misc/NameCircle';

import Dots from 'components/markup/loading/Dots';

const CallsOnCall = ({transfer_call}) => {

   return (
        <div>
            <div 
                className='bg-gradient-info text-center archk-call pt-4 pb-2 ' 
                style={{overflow: 'hidden'}}
            >
                <div className='pt-3'>
                    <NameCircle className="z-depth-2" width={70} contact={{given_name: 'Shannon', family_name: 'Maher'}}  />{' '}
                    <p className='text-sm mb-0 mt-3 text-white font-weight-bold'>{transfer_call.display_name}</p>
                    <p id="archk-cancel-transfer" className='text-sm mb-0 text-danger font-weight-bold cursor-pointer'><i className="fa-solid fa-phone-slash" /> END CALL</p>
                </div>
            </div>

            <div className='text-center archk-call pt-0 pb-2  border-bottm bg-gradient-info border-top' style={{overflow: 'hidden'}}>
                <div className="archk-on-call-wapper archk-call-buttons text-centr   mt-4 mb-2 px-4">
                    {transfer_call.can_merge ? (
                        <button id="archk-call-merge" className='btn btn-neutral btn-sm btn-block'>
                            Merge Calls <i className="display-5 fa-solid fa-shuffle" />
                        </button> 
                    ) : (
                        <button className='btn btn-neutral btn-sm btn-block' disabled>
                            Ringing<Dots />
                        </button> 
                    )}
                   
                </div>
            </div>
        </div>
   )

}


const mapStateToProps = state => {
	return {
	    transfer_call: state.call_center.transfer_call,
	};
};

export default connect(mapStateToProps, '')(CallsOnCall);
