import { connect } from 'react-redux';
import { enableDialing } from 'store/functions/call_center';

import NameCircle from 'components/markup/misc/NameCircle';
import Dots from 'components/markup/loading/Dots';

const CallsNotSetup = ({call_center}) => (
    <div className='bg-gradient-dark text-center archk-call pt-4 pb-2 cursor-pointer' >
        <div className='pt-3'>
            <NameCircle className="z-depth-2" width={70} contact={{given_name: 'N', family_name: 'A'}}  />{' '}
            
            <p className='text-sm mb--3 mt-4 text-white font-weight-bold'>
                {call_center.browser_dialing === 'loading' ? (
                    <span>Setting Up Audio<Dots /></span>
                ) : (
                    <span><i className="fa-solid fa-volume-xmark mr-2 text-danger" /> Audio Not Enabled</span>
                )}

            </p>

            <div className='p-3 mt-2 mb-1'>
                <button onClick={enableDialing} className='btn btn-block btn-sm btn-outline-info' >
                    Click To Enable Audio
                </button>
            </div>
            
        </div>
    </div>
)


const mapStateToProps = state => {
	return {
        call_center: state.call_center,
	};
};

export default connect(mapStateToProps, '')(CallsNotSetup);
