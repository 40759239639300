import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import formatText from 'utils/formatText'
import moment from 'moment';

const NavbarSearchResultsSearch = ({pageHistories, setShowResults}) => (
    <div className="results-wrapper bg-secondary">
        <div className="results-section">
            <ul>
                {!pageHistories.length ? (
                    <li className=" d-block hide-hover">No Page Histories Found</li>
                ) : pageHistories.map(history => (
                    <Link to={history.pathname}>
                        <li className=" d-block mb-3 px-5" onClick={() => setShowResults(false)}>
                            <Row>
                                <div style={{width: 40}} className="col-auto align-self-center pl-2">
                                    {history.description.includes('Contact') ? (
                                        <i className="display-4 fas fa-user text-warning mr-2" />
                                    ) : (
                                        <i className="display-4 fas fa-gavel text-info mr-2 " />
                                    )}
                                </div>
                                <Col className=" align-self-center pl-3">
                                    <Row>
                                        <Col xs={7}>
                                            <p className="text-s mb--2 text-dark">{formatText(history.description).stripHTML(100)}</p>
                                        </Col>
                                        <Col xs={5} className="text-right">
                                          
                                        </Col>
                                    </Row>
                                    <p className="text-sm text-muted mb-2">https://app.intuidesk.com{history.pathname}</p> 
                                </Col>
                                <div style={{width: 80}} className="col-auto align-self-center text-right">
                                    <div className='mb--3'><small>{moment.unix(history.created_at).format("MM/DD/YYYY")}</small></div>
                                    <div><small>{moment.unix(history.created_at).format("h:mm A")}</small></div>
                                </div>
                            </Row>
                        
                        </li>
                    </Link>
                ))}
            </ul>
        </div>
    </div>
)

export default NavbarSearchResultsSearch;