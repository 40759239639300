import { useCallback, useState } from "react";
import { connect } from 'react-redux';
import { Badge, Card, Container } from "reactstrap";

import api from 'api';
import { Link } from 'react-router-dom';

import Table from 'components/functional/tables/Standard';
import TableIcons from 'components/markup/icons/TableIcons';
import ApiError from "components/markup/layout/ApiError";
import ObjectFinder from 'components/system/Objects/Finder';

import Circle from 'components/markup/loading/Circle';
import HeaderNavigation from 'components/markup/layout/HeaderNavigation';
import { Helmet } from 'react-helmet';

import moment from 'moment';

const LocationsAll = ({setTab, match, selected_division, DOMAINS}) => {

    const [ loading, setLoading ] = useState(false);
    const [ sizePerPage, setSizePerPage ] = useState(15);
    const [ tableData, setTableData ] = useState({ data: [], total_documents: 0 })
    const [ error, setError ] = useState(false);


    // helper for this.downloadCSV and this.query
    const fetchData = useCallback((params, isCSV, shouldReload) => new Promise (async resolve => {
        if(!params) params = { searchString: '', page: 1,  skip: 0,  sizePerPage,  limit: sizePerPage }
        if(shouldReload) setLoading(true)

        let filter = {  }
        if(match.params._id && match.params._id !== 'all') filter.lead_source = match.params._id

        const query = await api.affiliates.leadSearch(selected_division._id, { ...params, filter, isCSV })
        if(shouldReload) setLoading(false)

        if(!query.data) return setError(query.message)
        return resolve(query.data)
    }), [sizePerPage, selected_division._id, match.params._id])

    // run query to get data from the server
    const query = useCallback(async (params) => {
        const data = await fetchData(params)
        setTableData({ data: data.documents, total_documents: data.total_documents })
    }, [fetchData])

   
 
    const columns = [
        {
            dataField: "given_name",
            text: 'Lead Identifier',
            formatter: (cell, row) => row.name === 'no data' ? (
                <div style={{minHeight: 150}}>No Data To Show</div> 
            ) : (
                <Link to={`/referrals/${row._id}`}>
                    <div>
                        <div>{row.display_name ? row.display_name : <span className="text-warning">Unknown Name</span>}</div>
                        {row.postal_code ? (
                            <div>ZIP: {row.postal_code}</div>
                        ) : ''}
                    </div>
                </Link>

            )
        },
        {
            dataField: "created_at",
            text: 'Created',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                <div>
                    <div>{moment.unix(row.created_at).format('h:mm A')}</div>
                    <div>{moment.unix(row.created_at).format('MMM Do, YYYY')}</div>
                </div>
            )
        },
        {
            dataField: "duplicate",
            text: 'Duplicate',
            formatter: (cell, row) => row.name === 'no data' ? '' : (
                row.duplicate ? (
                    <Badge style={styles.badge} color="danger">YES</Badge>
                ) : (
                    <Badge style={styles.badge} color="success">NO</Badge>
                )
            )
        },
        {
            dataField: "email",
            text: 'Email',
            formatter: (cell, row) => row.email
        },
        {
            dataField: "phone",
            text: 'Phone',
            formatter: (cell, row) => row.phone
        },
       
        {
            dataField: "lead_source",
            text: 'Lead Source',
            formatter: (cell, row) => row.name === 'no data' ? '' : <ObjectFinder collection="lead_sources" _id={row.lead_source} />
        },
      
        {
            dataField: "_id",
            headerStyle: {textAlign: 'right',},
            text: 'Actions',
            headerFormatter: () => {
                return <div id="archk-tutorial-contacts-actions">Actions</div>
            },
            formatter: (cell, row) => {
                return (
                    row.name === 'no data' ? '' :
                    <div className="text-right">
                         <TableIcons
                            icons={[
                                {
                                    icon: 'fas fa-external-link-alt',
                                    color: 'success', 
                                    wrapper: Link, 
                                    to: `/referrals/${row._id}`,
                                },
                               
                            ]}
                        />
                    </div>
                )
            }
        },
    ]

    if(error) return <ApiError err={error} />
    if(loading) return <div><Circle /></div>



    return (
        <>

        <Helmet>
            <title>{`Search Referrals`}</title>
            <meta name="description" content="Search Referrals" />
        </Helmet>

        <HeaderNavigation 
            title={'Referrals'}
            description={"View All"}
        />

        <Container fluid>
    
            <Card className="card-color card-primary table-fixed">
                <Table
                    tableClassNames={tableData.data && tableData.data.length ? '' : 'table-no-data'}
                    placeholder="Search..."
                    query={query}
                    columns={columns}
                    data={tableData.data.length ? tableData.data : [{_id: 'test', name: 'no data'}]}
                    totalDocuments={tableData.total_documents}
                    sizePerPage={sizePerPage}
                    onSizePerPageChange={(value) => setSizePerPage(parseInt(value))}
                />
            </Card>

        </Container>

        </>
    )

}

const styles = {
    badge: {
        width: 100  
    }
}

const mapStateToProps = state => {
	return {
        DOMAINS: state.load.DOMAINS,
	    selected_division: state.state.selected_division,
	};
};

export default connect(mapStateToProps, '')(LocationsAll);